export function renderSidebarNotification($icon) {
  $icon.each(function(i, e) {
    if ($(e).closest('li').hasClass('d-none')) {
      // 非表示のメニューの場合は通信しない
      // 親DOMのliタグにd-noneがあると非表示であると判定する
      return;
    }
    $.ajax({
      type: "GET",
      url: $(e).attr('data-url'),
      dataType: "json"
    }).done(function(data) {
      if (data == 0) {
        $(e).addClass("d-none");
      } else {
        $(e).text(data).removeClass("d-none");
      }
    }).fail(function() {
      // 通信に失敗した時もアイコンを非表示にする
      $(e).addClass("d-none");
    }).always(function() {
      checkLowerSideBarNotification();
    });
  });
}

// 配下のメニューに通知が表示された場合
function checkLowerSideBarNotification() {
  // 自身の親メニューに!を表示するパターン("sidebar-notification-upper"クラスのアイコン)
  $('.sidebar-notification-upper').each(function(i, e) {
    if ($(e).closest('li').find('.sidebar-notification:not(.d-none)').length > 0) {
      $(e).removeClass('d-none');
    } else {
      $(e).addClass('d-none');
    }
  });

  // 自身の親メニューに配下の合計数を表示するパターン("sidebar-notification-upper-sum"クラスのアイコン)
  $('.sidebar-notification-upper-sum').each(function(i, e) {
    if ($(e).closest('li').find('.sidebar-notification:not(.d-none)').length > 0) {
      let num = $(e).closest('li').find('.sidebar-notification:not(.d-none)').map(function(i, e) {
        return parseInt($(e).text());
      }).toArray().reduce((sum, v) => sum + v, 0);
      $(e).removeClass('d-none').text(num);
    } else {
      $(e).addClass('d-none').text(0);
    }
  });
}