import "js/base"
import "data-confirm-modal"
import * as FieldUtils from "js/lib/field-utils"
import { downloadFromBlob } from 'js/lib/blob-download';

// PDF inline表示用のiframeの差し込みをする関数
// 引数でスクロールするか、クローズボタンを出すか制御可能
export function renderInlinePdf(elem, scroll_to = true, close_btn = true) {
  let $v = $('#inline-view-field');

  if (close_btn) {
    $('#inline-view-close').removeClass("d-none");
    $('#inline-view-close button').unbind("click"); // 多重bind防止
    $('#inline-view-close button').bind("click", function() {
      $('#inline-view-close').addClass("d-none");
      $v.empty();
      // PDF用iframe消去後、添付ファイル一覧エリアにスクロールを戻す（？）
      $('html,body').animate({scrollTop: $('#attachment-field').offset().top - 10}, 400);
    });
  } // close_btn

  $v.empty();
  let $f = $('<iframe></iframe>');
  $f.css({
    width: "100%",
    height: "100vh"
  });
  $f.attr('src', $(elem).attr('href') + "?inline=1");
  $v.append($f);
  if (scroll_to) {
    setTimeout(function() {
      // PDF用iframeがだいたい収まるようにスクロールする
      $('').animate({scrollTop: $f.offset().top - 20}, 400);
    }, 100);
  } // scroll_to
}

// 添付ファイルダウンロードイベント
export function setupDownloadAttachment() {
  // 対象ファイルの種類に応じて動作を変える
  // PDFの場合はクリックされたらinline表示、それ以外はダウンロードの確認
  $('.attachment-field').on('click', '.attachment-content a', function(evt) {
    if ($(this).attr('data-content-type') == "pdf") {
      //PDFは別タブでinline表示するため、そのまま画面遷移する
    }
    else {
      evt.preventDefault();
      evt.stopPropagation();

      let that = this;
      dataConfirmModal.confirm({
        title: 'ダウンロード確認',
        text: '本当にダウンロードしますか？',
        commit: 'ダウンロード',
        cancel: 'キャンセル',
        modalClass: 'custom-modal',
        commitClass: 'btn-navy',
        onConfirm: function() {
          $.ajax({
            url: $(that).attr('href'),
            method: 'GET',
            xhrFields: {
              responseType: 'blob'
            }
          }).done(function(data, textStatus, request) {
            downloadFromBlob(data, request);
          }).fail(function(jqxhr, textStatus, errthrown) {
            $.notify('ダウンロードに失敗しました', { type: 'warning' });
          })
        }
      });
    }
  });
};

// 添付ファイルのinputのname属性のprefix
// 画面によって異なるので設定できるようにする
let input_name = "input_name";
// 添付ファイル追加・削除イベント
export function setupAttachmentField(iname) {
  input_name = iname;
  $('#attachment-wrapper').on('click', '.delete-btn', function(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    var that = this;
    dataConfirmModal.confirm({
      title: '削除確認',
      text: '本当に削除しますか？',
      commit: '削除',
      cancel: 'キャンセル',
      modalClass: 'custom-modal',
      onConfirm: function() {
        // 1行しかない場合には、削除前に新しく行を作る
        if ($(".attachment-new-field").length === 1 &&
            $(that).closest(".attachment-field").hasClass("attachment-new-field")) {
          addAttachmentFieldRow();
        }
        $(that).closest(".attachment-field").remove();
      }
    });
  });

  // 一意に特定するためにnewを指定
  $("#attachment-wrapper").on("change",'.attachment-new-field input[type="file"]', function(e) {
    dispPreviewAttachment($(this));
  });

  // ドラッグ&ドロップの準備
  setupDnD();

  // 登録済みのファイル編集を止める
  $('#attachment-wrapper').on('click','.attachment-file-input', function(evt) {
    evt.stopPropagation();
  });

  $('#attachment-wrapper').on('click', function() {
    $(this).find('.attachment-new-field .attachment-file-input').click();
  })
};

function addAttachmentFieldRow() {
  let $lastAttachmentFieldRow = $(".attachment-new-field").last();

  let $newAttachmentFieldRow = $lastAttachmentFieldRow.clone();
  // initAttachmentFieldRow($newAttachmentFieldRow);
  let newIndex = FieldUtils.getMaxIndex(".attachment-field") + 1;

  // index付与
  $newAttachmentFieldRow.attr("data-index", newIndex);

  $newAttachmentFieldRow.find(`[name^='${input_name}']`).each(function(i, e) {
    FieldUtils.replaceFieldIndex($(e), newIndex);
    FieldUtils.clearFeildValue($(e));
  });

  $newAttachmentFieldRow.insertAfter($lastAttachmentFieldRow);
}

function dropedAttachmentFieldRow($field) {
  $field.find('.delete-btn').removeClass('d-none');
  $field.find('.attachment-content').removeClass('d-none');
}

let counter = 0;

// drag & drop対応
function setupDnD() {
  $('#attachment-wrapper').on('dragover', function(evt) {
    evt.preventDefault();
  }); // on dragenter dragover
  $('#attachment-wrapper').on('drop', function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    counter = 0;
    $(this).css({ border : "2px dashed #dbdbdb" });
    if (! $(this).find('.attachment-new-field input[type=file]').get(0)) {
      return; // 単に無視する
    }
    var files = evt.originalEvent.dataTransfer.files;
    if (files.length > 1) {
      alert("アップロードはひとつずつお願いします");
      $(this).css({ border : "2px dashed #dbdbdb" }); // dragenterで太くなった枠を戻す
      return;
    }
    $(this).find('.attachment-new-field input[type=file]').get(0).files = files;
    $(this).find('.attachment-new-field input[type=file]').change(); // ファイルをアップロードしたことを通知
  }); // on drop
  $('#attachment-wrapper').on('dragenter', function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    counter++;
    if (! $(this).find('input[type=file]').get(0)) {
      return; // 単に無視する
    }
    $(this).css({ border : "2px dashed green" });
  }); // on dragenter dragover
  $('#attachment-wrapper').on('dragleave', function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    counter--;
    if (! $(this).find('input[type=file]').get(0)) {
      return; // 単に無視する
    }
    if (counter == 0) {
      $(this).css({ border : "2px dashed #dbdbdb" });
    }
  }); // on dragleave
}

function dispPreviewAttachment($input) {
  if ($(".attachment-new-field").length === 1) {
    addAttachmentFieldRow();
  }
  dropedAttachmentFieldRow($input.closest('.attachment-field'));
  let $field = $input.closest('.attachment-new-field');
  $field.removeClass("attachment-new-field");
  $field.addClass("attachment-preview-field");

  let file = $input.prop('files')[0];
  $field.find('.attachment-content span').text(file.name);

  // inputを機能させないために移動する
  $input.attr('readonly', true);
  $input.appendTo($input.closest('.attachment-field'));
}
