// フォームのフィールドを操作する関数群
// 主に複数入力するフィールドの操作について

// indexの最大値を取得する
// data-indexに設定してある想定
export function getMaxIndex(selector) {
  let max = -1; 
  $(selector).each(function(i, e) {
    var num = parseInt($(e).attr("data-index"), 10);
    if (num > max) {
      max = num; 
    }
  });
  return max;
};

// target内のselectorでindexの最大値を取得する
// data-indexに設定してある想定
export function getMaxIndexIn(selector, $target) {
  let max = -1; 
  $target.find(selector).each(function(i, e) {
    var num = parseInt($(e).attr("data-index"), 10);
    if (num > max) {
      max = num; 
    }
  });
  return max;
};

// 最後のindexを置換する
export function replaceFieldIndex($field, index) {
  // idがtask_target_urls_attributes_1_urlのようになっているので、最後の_数字_を置換する
  let newId = $field.attr("id").replace(/(.*)_\d+_/, "$1_" + index + "_");
  // nameがtask[target_urls_attributes][1][url]のようになっているので、最後の[数字]を置換する
  let newName = $field.attr("name").replace(/(.*)\[\d+\]/, "$1[" + index + "]");
  $field.attr("id", newId);
  $field.attr("name", newName);
};

// 指定位置のindexを置換する
// positionは0始まり
export function replaceFieldIndexAt($field, index, position) {
  // idがtask_target_urls_attributes_1_urlのようになっているので、_数字_を置換する
  let count = 0;
  let newId = $field.attr("id").replace(/_\d+_/g, function(match) {
    if (count++ == position)
      return `_${index}_`;
    else
      return match;
  });

  // nameがtask[target_urls_attributes][1][url]のようになっているので、最後の[数字]を置換する
  count = 0;
  let newName = $field.attr("name").replace(/\[\d+\]/g, function(match) {
    if (count++ == position)
      return `[${index}]`;
    else
      return match;
  });

  $field.attr("id", newId);
  $field.attr("name", newName);
};

// 指定位置のindexを置換する
// positionは0始まり
export function replaceLabelIndexAt($label, index, position) {
  // fortask_target_urls_attributes_1_urlのようになっているので、_数字_を置換する
  let count = 0;
  let newFor = $label.attr("for").replace(/_\d+_/g, function(match) {
    if (count++ == position)
      return `_${index}_`;
    else
      return match;
  });

  $label.attr("for", newFor);
};

// フィールドの中身をクリアする。tagの種類によって処理を変える
export function clearFeildValue($field) {
  switch($field.prop("tagName")) {
    case "INPUT":
      let fieldType = $field.attr("type");
      // checkboxとradioボタンの場合は値を消さない
      if (fieldType != "checkbox" && fieldType != "radio")
        $field.val("");
      break;
    case "TEXTAREA":
      $field.val("");
      break;
    case "SELECT":
      $field.find("option").prop("selected", false);
      break;
  }
};
