// Content-Dispositionからファイル名を取得する
function getFilename(content_disposition) {
  const regex = /filename\*=UTF-8''(.*)/;
  const matches = regex.exec(content_disposition);
  if (matches != null && matches[1]) {
    return matches[1];
  }
  // 何らかの理由で正規表現にマッチしなかった場合でもファイルのダウンロードを保証するため
  return "error.zip";
};

// blobからデータを取り出してダウンロードさせる
export function downloadFromBlob(data, request) {
  const content_disposition = request.getResponseHeader("Content-Disposition");
  const filename = decodeURIComponent(getFilename(content_disposition));
  // ファイルダウンロード
  if (window.navigator.msSaveBlob) { // for IE
    window.navigator.msSaveBlob(data, filename);
  }
  else {
    var url = (window.URL || window.webkitURL);
    var urldata = url.createObjectURL(data);
    var a = $('<a/>');
    a.attr('href', urldata);
    a.attr('download', filename);
    a.get(0).click();
    url.revokeObjectURL(urldata);
  }
}
