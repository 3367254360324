import "bootstrap-material-design"
import "vendor/material-dashboard-pro-html-v2.2.2/assets/js/material-dashboard"
import "vendor/material-dashboard-pro-html-v2.2.2/assets/js/plugins/bootstrap-notify"
import { DatatablesHandler } from './datatables-handler'

var intervalId = null;

// TODO: リリース前に3分ごとに変更
// 開発中はログが流れてしまうので
const noticeInterval = 15 * 60 * 1000; // 通知情報を更新する間隔
// const noticeInterval = 3 * 60 * 1000; // 通知情報を更新する間隔
const timeAgoInterval = 60 * 1000;    // ~前を更新する間隔

export function bindNoticeEvent() {
  // 通知アイコンのドロップダウンがない場合は通知情報を更新するロジックを走らせない
  // ログインページなどのように，未ログイン状態の場合はこれに該当する
  if ($("#dropdown-notice").length == 0) {
    return;
  }

  // 定期的に通知情報をサーバから取得
  intervalId = setInterval(updateNoticeInfo, noticeInterval, []);
  // TODO: リリース時は以下のコメントアウトを外す
  // ページ読み込み時にhttpリクエストが発生してしまうので，
  // コメントアウトすることで，読み込み時のログを綺麗にする
  updateNoticeInfo(); // 起動時にも取得

  // ~{分,時間,日}前を更新
  setInterval(updateTimeAgo, timeAgoInterval, []);

  // ドロップダウンが閉じられたら既読をつけて，通知情報を更新
  $("#dropdown-notice").parent().on('hide.bs.dropdown', function() {
    // モーダルを開いていたら既読を更新しない
    if (isModalShown()) {
      return;
    }
    let readIds = $.map($("#dropdown-notice").find('.dropdown-item.notice-item'), function(elm, idx) {
      return $(elm).attr('data-notice-user-id');
    });
    updateNoticeInfo(readIds);
  });

  // ドロップダウンを開いたら通知情報を更新
  $("#dropdown-notice").parent().on('show.bs.dropdown', function() {
    updateNoticeInfo();
  });

  // ドロップダウン内のリンクをクリックしたら既読済を送信
  $("#dropdown-notice").on('click', 'a.notice-item', function() {
    let readIds = $.map($("#dropdown-notice").find('.dropdown-item.notice-item'), function(elm, idx) {
      return $(elm).attr('data-notice-user-id');
    });
    updateNoticeInfo(readIds);
    return true;
  });

  setupNoticeModal();
}

// 他のファイルから呼ぶ用
export function UpdateNoticeInfo() {
  updateNoticeInfo(Array.from(readIds));
}
// 通信エラーが発生した時に通知をするかのフラグ
// 何度も通信エラー通知を出すのは煩わしいためフラグで表示するか管理する
var errNotifyFlag = true;
// _readIds: 既読済み通知id(NoticeUser.id)配列
// _readIdsがあると既読を更新して，通知情報を取得する
function updateNoticeInfo(_readIds) {
  $.ajax({
    type: 'POST',
    url: '/notices/notices/update_notice_info',
    dataType: 'script',
    data: { _method: 'GET', read_ids: _readIds }
  }).done(function () {
    updateTimeAgo();
    errNotifyFlag = true; // 通信が復活したので，次回以降通信エラーが発生したら通知するようにする
  }).fail(function() {
    if (errNotifyFlag) {
      $.notify(
        {
          message: "通信に問題が発生したため、通知情報を取得することができません。"
        },
        {
          type: 'warning',
          timer: 4000,
          z_index: 1100, // modalのz-indexが1050，navbar(通知ドロップダウン含む)のz-indexが1029なのでそれらより大きくする
          placement: {
            from: 'top',
            align: 'center'
          }
        }
      );
    }
    errNotifyFlag = false; // エラー通知済みなのでエラー通知を出さないようにする
  });
}

// ~{分,時間,日}前を更新
function updateTimeAgo() {
  $(".notice-time-ago").each(function(idx, elm) {
    $(elm).html(getTimeAgo($(elm).attr('data-noticed_at')));
  });
}

function getTimeAgo(time) {
  let diff = new Date() - new Date(time)
  let diffSecond = Math.floor(diff / 1000);
  let diffMinute = Math.floor(diff / (60 * 1000));
  let diffHour = Math.floor(diff / (60 * 60 * 1000));
  let diffDay = Math.floor(diff / (24 * 60 * 60 * 1000));

  if (diffDay > 0) {
    return `${diffDay}日前`
  } else if (diffHour > 0) {
    return `${diffHour}時間前`
  } else if (diffMinute > 0) {
    return `${diffMinute}分前`
  } else {
    return "1分未満"
  }
}

// 既読にするNoticeUser.idの配列
// データテーブルで表示したidを保存
// モーダルを閉じたら既読idをサーバに送信
var readIds = new Set();

const noIdx = 0;
const nameIdx = 1;
const noticedAtIdx = 2;
const isReadIdx = 3;
export const urlIdx = 4;
const idIdx = 5;
function setupNoticeModal() {
  const url = "/notices/notices/";

  let dtHandler = new DatatablesHandler($('table#notice-datatable'), url, {
    'columnDefs' : [
      { 'targets' : [ isReadIdx, urlIdx, idIdx ], 'visible' : false },
      { 'targets' : [ noIdx, urlIdx, noticedAtIdx, isReadIdx, idIdx ], 'searchable' : false },
      { 'targets' : [ noIdx, nameIdx, isReadIdx, urlIdx, idIdx ], 'orderable' : false },
    ],
    'order' : [ noticedAtIdx, "desc" ],
    'columns': [
      { 'width': '3rem', 'className': 'number-column w3rem' },
      { 'width': "auto" },
      { 'width': '4rem', 'className': 'number-column w4rem' },
    ],
    'createdRow': onDrawingRow,
    'ajax': {
      'data' : (d) => $.extend( {}, d, { '_method' : 'GET', 'unread_only' : isUnreadOnly() } )
    },
    'initComplete' : function() {
      let w = this.closest('div.dataTables_wrapper');
      let d = w.find('div.row > div').eq(1);
      $('#notice-modal-check-field').prependTo( d.find('.dataTables_filter') );
      w.find('.dataTables_filter input[type="search"]').removeClass('form-control');
      w.find('.dataTables_filter input[type="search"]').removeClass('form-control-sm');
      w.find('.dataTables_filter input[type="search"]').addClass('bs-form-control');
      w.find('.dataTables_filter input[type="search"]').addClass('bs-form-control-sm');
      w.find('.dataTables_filter label').css({
        "marginTop" : "0.25rem",
        "marginBottom" : "0.25rem",
      });

    },
    'drawCallback' : function() {
      let w = this.closest('div.dataTables_wrapper');
      w.find('.dataTables_paginate > .pagination').addClass('pagination-navy');
    },

    // 初期化時にロードしない
    // 各ページの読み込み時にdatatableのhttpリクエストを回避することで，読み込み時のログを綺麗にする
    "deferLoading": 0
  });

  // 行をクリックするとurl列にあるurl(or path)のページへ飛ぶようにする
  dtHandler.attachRowClickEvent(function() {
    const data = dtHandler.dataTable.row(this).data();
    updateNoticeInfo(Array.from(readIds)); // 既読がつかないので，既読データを送信
    window.location.href = data[urlIdx];
  });

  // 未読フィルタースイッチが変わったらデータテーブルをリロード
  $('#unread-only-checkbox').on('change', function() {
    dtHandler.reloadAjaxDatatable();
  });

  // ドロップダウンを開いたら中のデータテーブルをリロード
  // 既読済み集合を初期化
  // モーダルを開いたタイミングだと，リロード前のdatatableの状態が一瞬見えてしまうので
  // ドロップダウンを開いたタイミングのほうがいいと思う
  $("#dropdown-notice").parent().on('show.bs.dropdown', function() {
    readIds.clear();
    dtHandler.reloadAjaxDatatable();
  });

  // モーダルを開いたらテーブル幅を再計算
  // モーダルを開くまでwidthが0pxとなってしまう(?)ため
  $('#noticeModal').on('shown.bs.modal', function() {
    dtHandler.dataTable.columns.adjust().draw();
  });

  // モーダルを閉じたら表示した未読通知を既読にする
  $('#noticeModal').on('hidden.bs.modal', function() {
    updateNoticeInfo(Array.from(readIds));
    readIds.clear();
  });
}

// データテーブルのcreatedRowオプションに渡す関数
function onDrawingRow(row, data, _) {
  $(row).addClass("clickable");

  // updateTimeAgo関数の適用対象になるようにする
  $(row).find(`td:eq(${noticedAtIdx})`).addClass('notice-time-ago');
  $(row).find(`td:eq(${noticedAtIdx})`).attr('data-noticed_at', data[noticedAtIdx]);

  // 時刻を~{分,時間,日}前に変換
  $(row).find(`td:eq(${noticedAtIdx})`).html(getTimeAgo(data[noticedAtIdx]));

  // 既読・未読でデザインを分ける
  if (data[isReadIdx]){
    $(row).addClass("read-notice");
  } else {
    if (isModalShown()) {
      // モーダルを開いていてなおかつ未読の通知idを取得
      // 見た判定にすることで，次にモーダル開いた時に既読扱いとなる
      readIds.add(data[idIdx]);
    }
    $(row).addClass("unread-notice");
  }
}

function isModalShown() {
  return $('#noticeModal').data('bs.modal') || {isShown: false}.isShown;
}

// 未読フィルターがオンか
function isUnreadOnly() {
  return $('#unread-only-checkbox').prop('checked');
}
