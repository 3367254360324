export function bindSupplierUserEmailEvent() {
  $("#supplier-user-email-link").on("click", function(e) {
    $.ajax({
      url:  `/supplier_users/email/edit`,
      dataType: 'script',
      type: 'POST',
      data: {
        _method: 'GET',
      },
    }).done(function(data) {
      $('#supplier-user-email-modal').modal('show');
    });
  });
};
