import "bootstrap-material-design"
import "vendor/material-dashboard-pro-html-v2.2.2/assets/js/material-dashboard"
import "bootstrap-notify"

export function bindItemSearchEvent() {
  let $modal = $('#itemSearchModal');
  let $barcodeForm = $modal.find('#item_search_barcode');
  let $toggleButtons = $modal.find('#toggle-button');
  let $onButton = $modal.find('#scan-mode-off');

  // モーダルが開かれた時
  // バーコード入力欄にフォーカスを当てる
  $modal.on('shown.bs.modal', function(){
    $barcodeForm.focus();
  });

  // バーコード入力欄にフォーカスが当たった/外れた時
  // ボタンをフォーカスが当たっているかでデザインを変える
  $barcodeForm.on('focus blur', function() {
    if ($barcodeForm.is(':focus')) {
      $barcodeForm.val('');
      $toggleButtons.find('#scan-mode-on').removeClass('d-none');
      $toggleButtons.find('#scan-mode-off').addClass('d-none');
    } else {
      $toggleButtons.find('#scan-mode-on').addClass('d-none');
      $toggleButtons.find('#scan-mode-off').removeClass('d-none');
    }
  });

  // OFFボタンを押した時
  // ONにする
  $onButton.on('click', function() {
    $barcodeForm.focus();
  });

  // バーコードがスキャンされた時
  // バーコード内容をサーバー側に送信
  $barcodeForm.on('keydown', function(e) {
    if (e.key === "Enter") {
      searchByBarcode($barcodeForm.val());
      $barcodeForm.val('');
    }
  });
}

function notFound() {
  $.notify({
    message: "バーコードが正しく読まれなかったか、システム上に商品が存在しませんでした。"
  },{
    type: 'warning',
    timer: 4000,
    z_index: 2000,
    placement: {
      from: 'top',
      align: 'center'
    }
  });
}

function searchByBarcode(barcode) {
  $.ajax({
    type: 'POST',
    url: '/search/items/barcode',
    dataType: 'json',
    data: {
      _method: 'GET',
      barcode: barcode
    }
  }).done(function(data) {
    console.log(data)
    if (data.hit) {
      location.href = data.url;
    } else {
      notFound();
    }
  }).fail(function() {
    notFound();
  });
}
