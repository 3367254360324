// 操作がないかどうかチェックする間隔
const checkInterval = 60 * 1000;
// 一定期間操作がなければログアウトする時間
const logoutInterval = 2 * 60 * 60 * 1000;
// const logoutInterval = 30 * 60 * 1000;

let intervalId = null;

export function bindAutoLogoutEvent() {
  // とりあえず初回に時間をセットしておく
  localStorage.setItem("lastOperationTime", $.now());

  let onSetLocalStorage = false;
  $('body').on('click keypress scroll wheel mousewheel mousemove',function() {
    if (onSetLocalStorage) {
      return;
    }
    onSetLocalStorage = true;
    localStorage.setItem("lastOperationTime", $.now());
    // 負荷が高くならないように、1秒タイマーセットする
    setTimeout(function(){
      onSetLocalStorage = false;
    }, 1000);
  });

  intervalId = setInterval(checkLogoutInterval, checkInterval, []);
}


function checkLogoutInterval() {
  let lastOperationTime = localStorage.getItem("lastOperationTime");
  let now = $.now();

  // 設定されてたらチェック
  if (lastOperationTime !== null) {
    let logoutTime = parseInt(lastOperationTime) + logoutInterval;
    if (now > logoutTime) {
      // 一定時間操作がなければlogout処理
      clearInterval(intervalId);
      //localStorage.removeItem("lastOperationTime");
      autoLogout();
    }
  }
}

function autoLogout() {
  $.ajax({
    url:  '/logout',
    dataType: 'script',
    type: 'POST',
    data: {
      _method: 'DELETE',
      timeout: true
    },
  }).done(function() {
  });
}

