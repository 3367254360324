// サイドメニューでアクティブな項目を見つけ, 親のメニューを開いた状態にする
export function bindSideMenuEvent() {
  $(".nav-item.active").parents(".collapse").addClass("show");
  $(".nav-item.active").parents(".collapse").prev("a").removeClass("collapsed").attr("aria-expanded", "true");
}

// 子供のメニューが1つでも存在したら，親のメニューを表示する
export function showUpperMenu() {
  $('.nav-item.upper-menu').each(function(i, um) {
    // 許可されている子メニューにはpermitted-menuというクラスをヘルパーメソッドでつけている
    // そのDOMを探索して，1つでもあれば親メニューも表示するようにしている
    if ($(um).find(".nav-item.permitted-menu").length > 0) {
      $(um).removeClass("d-none");
    }
  });
}
